<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column">
            <h2 class="title is-3">Пользователь с формы, id {{currentFormUser.id}}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <h1 class="subtitle is-6">Лендинг</h1>
          <h1 class="title is-5">{{currentFormUser.landing ?
            currentFormUser.landing.id + '. ' + currentFormUser.landing.package_name :
            'Нет' }}</h1>

          <h1 class="subtitle is-6 mt-5">Форма</h1>
          <h1 class="title is-5">{{currentFormUser.form ?
            currentFormUser.form.id + '. ' + currentFormUser.form.name :
            'Нет' }}</h1>

          <h1 class="subtitle is-6 mt-5">Ответственный (владелец лендинга)</h1>
          <h1 class="title is-5">{{currentFormUser.profile ?
            currentFormUser.profile.id + '. ' + currentFormUser.profile.name :
            'Нет' }}</h1>

          <h1 class="subtitle is-6 mt-5">Дата</h1>
          <h1 class="title is-5">{{formatDateForPeople(currentFormUser.created_at)}}</h1>

          <h1 class="subtitle is-6 mt-5">Телефон</h1>
          <h1 class="title is-5">{{currentFormUser.phone_number}}</h1>


          <h1 class="subtitle is-6 mt-6 mb-3">Параметры</h1>
          <pre>{{currentFormUser.user_parameters}}</pre>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserFormDetailsModal',
  data() {
    return {
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.getters.IS_FORM_USER_OPENED;
      },
      set(value) {
        this.$store.commit('SET_FORM_USER_OPENED', value);
      },
    },
    currentFormUser: {
      get() {
        return this.$store.getters.GET_CURRENT_FORM_USER;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_FORM_USER", value);
      },
    },
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.currentFormUser = {};
      this.isOpen = false;
      this.isLoading = false;
    },
    formatDateForPeople(inputDate) {
      const date = new Date(inputDate);
      const now = new Date();

      // Применяем корректное локальное смещение
      const localDate = new Date(date.getTime() + now.getTimezoneOffset() * 60000 * -1);

      const isSameDay = (d1, d2) =>
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear();

      const isYesterday = (d1, d2) => {
        const yesterday = new Date(d2);
        yesterday.setDate(yesterday.getDate() - 1);
        return isSameDay(d1, yesterday);
      };

      const formatTime = (date) =>
        date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });

      const formatDateString = (date) => {
        const options = { day: "numeric", month: "long" };
        if (date.getFullYear() !== now.getFullYear()) {
          options.year = "numeric";
        }
        return date.toLocaleDateString("ru-RU", options);
      };

      if (isSameDay(localDate, now)) {
        return `сегодня ${formatTime(localDate)}`;
      } else if (isYesterday(localDate, now)) {
        return `вчера ${formatTime(localDate)}`;
      } else {
        return formatDateString(localDate);
      }
    },
  },
};
</script>

<style>
</style>
