<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список пользователей с форм сбора данных</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column has-text-right">
        <b-button @click="exportToCSV" type="is-primary">Выгрузить в CSV</b-button>
      </div>
    </div>

    <b-collapse
      class="card mb-7"
      animation="slide"
      aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="props.open">
          <p class="card-header-title">
            Фильтры
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <div class="columns">
            <div class="column is-6">
              <b-field label="Период от">
                <b-datepicker
                  v-model="filters.period_from"
                  ref="datepicker"
                  expanded
                  placeholder="От">
                </b-datepicker>
                <b-button
                  @click="$refs.datepicker.toggle()"
                  icon-left="calendar-today"
                  type="is-primary" />
              </b-field>

              <b-field label="Форма обратной связи">
                <b-select
                  v-model="filters.form_id"
                  placeholder="Выберите форму">
                  <option
                    v-for="option in forms"
                    :value="option.id"
                    :key="option.id">
                    {{ option.id + '. ' + option.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Ответственный">
                <b-select
                  v-model="filters.profile_id"
                  placeholder="Выберите ответственного">
                  <option
                    v-for="option in profiles"
                    :value="option.id"
                    :key="option.id">
                    {{ option.id + '. ' + option.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column is-6">
              <b-field label="Период до">
                <b-datepicker
                  v-model="filters.period_to"
                  ref="datepicker2"
                  expanded
                  placeholder="До">
                </b-datepicker>
                <b-button
                  @click="$refs.datepicker2.toggle()"
                  icon-left="calendar-today"
                  type="is-primary" />
              </b-field>

              <b-field label="Лендинг">
                <b-select
                  v-model="filters.landing_id"
                  placeholder="Выберите лендинг">
                  <option
                    v-for="option in applications"
                    :value="option.id"
                    :key="option.id">
                    {{ option.id + '. ' + option.package_name }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Телефон">
                <b-input
                  type="number"
                  placeholder="Телефон"
                  v-model="filters.phone_number">
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-6">
              <!-- Первая колонка с sub1 до sub8 -->
              <b-field label="Sub 1">
                <b-input
                  v-model="filters.sub1">
                </b-input>
              </b-field>

              <b-field label="Sub 2">
                <b-input
                  v-model="filters.sub2">
                </b-input>
              </b-field>

              <b-field label="Sub 3">
                <b-input
                  v-model="filters.sub3">
                </b-input>
              </b-field>

              <b-field label="Sub 4">
                <b-input
                  v-model="filters.sub4">
                </b-input>
              </b-field>

              <b-field label="Sub 5">
                <b-input
                  v-model="filters.sub5">
                </b-input>
              </b-field>

              <b-field label="Sub 6">
                <b-input
                  v-model="filters.sub6">
                </b-input>
              </b-field>

              <b-field label="Sub 7">
                <b-input
                  v-model="filters.sub7">
                </b-input>
              </b-field>

              <b-field label="Sub 8">
                <b-input
                  v-model="filters.sub8">
                </b-input>
              </b-field>
            </div>

            <div class="column is-6">
              <!-- Вторая колонка с sub9 до sub15 -->
              <b-field label="Sub 9">
                <b-input
                  v-model="filters.sub9">
                </b-input>
              </b-field>

              <b-field label="Sub 10">
                <b-input
                  v-model="filters.sub10">
                </b-input>
              </b-field>

              <b-field label="Sub 11">
                <b-input
                  v-model="filters.sub11">
                </b-input>
              </b-field>

              <b-field label="Sub 12">
                <b-input
                  v-model="filters.sub12">
                </b-input>
              </b-field>

              <b-field label="Sub 13">
                <b-input
                  v-model="filters.sub13">
                </b-input>
              </b-field>

              <b-field label="Sub 14">
                <b-input
                  v-model="filters.sub14">
                </b-input>
              </b-field>

              <b-field label="Sub 15">
                <b-input
                  v-model="filters.sub15">
                </b-input>
              </b-field>
            </div>
          </div>

        </div>
      </div>
      <footer class="card-footer pa-2 is-justify-content-flex-end">
        <b-button @click="clearFilters" class="mr-4">Очистить</b-button>
        <b-button @click="getFormsUsers" type="is-primary">Загрузить</b-button>
      </footer>
    </b-collapse>

    <b-collapse
      class="card mb-7"
      animation="slide"
      aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="props.open">
          <p class="card-header-title">
            Отображение
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open_view ? 'menu-up' : 'menu-down'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">

          <div class="columns">
            <div class="column is-6">
              <p><b-checkbox v-model="visibleColumns.sub1">Sub 1</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub2">Sub 2</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub3">Sub 3</b-checkbox></p>
              <p>Далее только для выгрузки ↓</p>

              <p><b-checkbox v-model="visibleColumns.sub4">Sub 4</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub5">Sub 5</b-checkbox></p>

              <p><b-checkbox v-model="visibleColumns.sub6">Sub 6</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub7">Sub 7</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub8">Sub 8</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub9">Sub 9</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub10">Sub 10</b-checkbox></p>

              <p><b-checkbox v-model="visibleColumns.sub11">Sub 11</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub12">Sub 12</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub13">Sub 13</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub14">Sub 14</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.sub15">Sub 15</b-checkbox></p>
            </div>

            <div class="column is-6">
              <p><b-checkbox v-model="visibleColumns.created_at">Дата</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.profile">Ответственный</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.landing">Лендинг</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.phone">Телефон</b-checkbox></p>
              <p><b-checkbox v-model="visibleColumns.form">Форма</b-checkbox></p>
            </div>
          </div>

        </div>
      </div>
      <footer class="card-footer pa-2 is-justify-content-flex-end">
        <b-button @click="clearView" class="mr-4">Очистить</b-button>
      </footer>
    </b-collapse>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="users.results"
      class="users-table"

      paginated
      backend-pagination
      :total="users.total"
      :per-page="params.perPage"
      @page-change="onPageChange"
    >
      <b-table-column label="Номер телефона" v-if="visibleColumns.created_at" v-slot="props">
        <span>{{formatDateForPeople(props.row.created_at)}}</span>
      </b-table-column>

      <b-table-column label="Номер телефона" v-if="visibleColumns.phone" v-slot="props">
        <span>{{props.row.phone_number}}</span>
      </b-table-column>

      <b-table-column label="Лендинг" v-if="visibleColumns.landing" v-slot="props">
        <span>{{props.row.landing ? props.row.landing.package_name : 'Нет'}}</span>
      </b-table-column>

      <b-table-column label="Ответственный" v-if="visibleColumns.profile" v-slot="props">
        <span>{{props.row.profile ? props.row.profile.name : 'Нет'}}</span>
      </b-table-column>

      <b-table-column label="Форма" v-if="visibleColumns.form" v-slot="props">
        <span>{{props.row.form ? props.row.form.name : 'Нет' }}</span>
      </b-table-column>

      <b-table-column label="Sub1" v-if="visibleColumns.sub1" v-slot="props">
        <span>{{props.row.user_parameters.aff_sub1 }}</span>
      </b-table-column>

      <b-table-column label="Sub2" v-if="visibleColumns.sub2" v-slot="props">
        <span>{{props.row.user_parameters.aff_sub2 }}</span>
      </b-table-column>

      <b-table-column label="Sub3" v-if="visibleColumns.sub3" v-slot="props">
        <span>{{props.row.user_parameters.aff_sub3 }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">

        <div>

          <b-tooltip label="Перейти">
            <b-button @click="openUser(props.row)" class="edit_icon">
              <svg xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 64 64;" viewBox="0 0 64 64" xml:space="preserve">
                    <g>
                      <g transform="translate(382.000000, 380.000000)">
                        <polyline points="-352.3,-343.4 -354.6,-345.7 -328.8,-371.4 -326.6,-369.2 -352.3,-343.4    "/>
                        <polyline points="-326,-354.9 -329.4,-354.9 -329.4,-368.6 -343.1,-368.6 -343.1,-372 -326,-372      -326,-354.9    "/>
                        <path d="M-334.6-324h-34.3c-2.8,0-5.1-2.3-5.1-5.1v-34.3c0-2.8,2.3-5.1,5.1-5.1h18.9v3.4h-18.9     c-0.9,0-1.7,0.8-1.7,1.7v34.3c0,0.9,0.8,1.7,1.7,1.7h34.3c0.9,0,1.7-0.8,1.7-1.7V-348h3.4v18.9C-329.4-326.3-331.7-324-334.6-324     " />
                      </g>
                    </g>
                  </svg>
            </b-button>
          </b-tooltip>

        </div>

      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="params.perPage"
            @change="getFormsUsers"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>

    </b-table>

    <FormUserDetailsModal />
    <OverlayLoader :show="isLoading"></OverlayLoader>

  </div>
</template>

<script>
import OverlayLoader from "@/app/shared/components/app-loader/Loader";
import FormUserDetailsModal from "@/app/client/forms/shared/modals/formUserDetailsModal.vue";

export default {
  name: "formsUsersList",
  components: {
    OverlayLoader,
    FormUserDetailsModal
  },
  data() {
    return {
      params: {
        type: '',

        // пагинация
        perPage: 20,
        page: 1
      },
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],

      filters: {
        period_from: new Date(),
        period_to: new Date(),
        form_id: null,
        landing_id: null,
        phone: null,
        profile_id: null,

        sub1: null,
        sub2: null,
        sub3: null,
        sub4: null,
        sub5: null,
        sub6: null,
        sub7: null,
        sub8: null,
        sub9: null,
        sub10: null,
        sub11: null,
        sub12: null,
        sub13: null,
        sub14: null,
        sub15: null,
      },

      visibleColumns: {
        created_at: false,
        landing: true,
        profile: true,
        phone: true,
        form: false,

        sub1: true,
        sub2: true,
        sub3: true,

        sub4: false,
        sub5: false,

        sub6: false,
        sub7: false,
        sub8: false,
        sub9: false,
        sub10: false,

        sub11: false,
        sub12: false,
        sub13: false,
        sub14: false,
        sub15: false
      }
    };
  },
  props: {
    open: false,
    open_view: false
  },
  created() {
    this.getFormsUsers();
    this.getApplications();
    this.getProfiles();
    this.getForms();
  },
  computed: {
    applications() {
      return this.$store.getters.APPLICATIONS ? this.$store.getters.APPLICATIONS.results : [];
    },
    users() {
      return this.$store.getters.GET_FORMS_USERS;
    },
    profiles() {
      return this.$store.getters.GET_PROFILES;
    },
    forms() {
      return this.$store.getters.GET_FORMS ? this.$store.getters.GET_FORMS.results : [];
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_FORMS_USERS_LOADING;
      },
      set(value) {
        this.$store.commit("SET_FORMS_USERS_LOADING", value);
      },
    }
  },
  methods: {
    onPageChange(page) {
      this.params.page = page;
      this.getFormsUsers();
    },
    getFormsUsers() {
      let data = {
        params: this.params,
        filters: Object.assign({}, this.filters)
      }

      data.filters.period_from = this.isValidDate(data.filters.period_from)
        ? this.formatDate(data.filters.period_from)
        : null;

      data.filters.period_to = this.isValidDate(data.filters.period_to)
        ? this.formatDate(data.filters.period_to)
        : null;

      this.$store.dispatch("GET_USERS_FORMS", data).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    getApplications() {
      this.$store.dispatch('GET_APPLICATIONS', { all: true });
    },
    getForms() {
      this.$store.dispatch('GET_FORMS', { page: 'all' });
    },
    getProfiles() {
      this.$store.dispatch('GET_PROFILES', {type: 'SET_PROFILES'});
    },
    formatDate(date) {
      return date.toLocaleDateString('ru-RU').split('.').reverse().join('-');
    },
    openUser(user_data) {
      console.log('Данные пользователя: ', user_data);
      this.$store.commit('SET_CURRENT_FORM_USER', user_data);
      this.$store.commit('SET_FORM_USER_OPENED', true);
    },
    exportToCSV() {
      let body = {
        'columns': this.visibleColumns,
        'filters': Object.assign({}, this.filters)
      }

      body.filters.period_from = this.isValidDate(body.filters.period_from)
        ? this.formatDate(body.filters.period_from)
        : null;

      body.filters.period_to = this.isValidDate(body.filters.period_to)
        ? this.formatDate(body.filters.period_to)
        : null;

      this.$store.dispatch("EXPORT_FORMS_USERS_TO_CSV", body).then(() => {
        this.$toast.success("Данные загружены!");
      }).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    formatDateForPeople(inputDate) {
      const date = new Date(inputDate);
      const now = new Date();

      // Применяем корректное локальное смещение
      const localDate = new Date(date.getTime() + now.getTimezoneOffset() * 60000 * -1);

      const isSameDay = (d1, d2) =>
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear();

      const isYesterday = (d1, d2) => {
        const yesterday = new Date(d2);
        yesterday.setDate(yesterday.getDate() - 1);
        return isSameDay(d1, yesterday);
      };

      const formatTime = (date) =>
        date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });

      const formatDateString = (date) => {
        const options = { day: "numeric", month: "long" };
        if (date.getFullYear() !== now.getFullYear()) {
          options.year = "numeric";
        }
        return date.toLocaleDateString("ru-RU", options);
      };

      if (isSameDay(localDate, now)) {
        return `сегодня ${formatTime(localDate)}`;
      } else if (isYesterday(localDate, now)) {
        return `вчера ${formatTime(localDate)}`;
      } else {
        return formatDateString(localDate);
      }
    },
    isValidDate(date) {
      return date instanceof Date && !isNaN(date.getTime());
    },
    clearFilters() {
      this.filters = {
        period_from: null,
        period_to: null,
        form_id: null,
        landing_id: null,
        phone: null,
        profile_id: null,

        sub1: null,
        sub2: null,
        sub3: null,
        sub4: null,
        sub5: null,
        sub6: null,
        sub7: null,
        sub8: null,
        sub9: null,
        sub10: null,
        sub11: null,
        sub12: null,
        sub13: null,
        sub14: null,
        sub15: null
      };
    },
    clearView() {
      this.visibleColumns = {
        created_at: false,
        landing: true,
        profile: true,
        phone: true,
        form: false,
        sub1: true,
        sub2: true,
        sub3: true
      };
    }
  },
};
</script>

<style>
</style>
